<!--
 * @Author: your name
 * @Date: 2021-11-30 10:27:06
 * @LastEditTime: 2022-01-04 17:47:27
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \sproutedus\src\views\product\page1.vue
-->
<template>
  <div id="page3">
    <!--  -->
    <div class="contain contain3" data-aos="fade-up" data-aos-once="true">
      <div class="bigTitle">
        <div class="bigTitle-contain">
          <img class="bananText" src="../../assets/321.gif" alt />
          <p>家庭教育大屏解决方案是以电视教育为切入点，依托TV大屏直播、点播技术，联通IPAD、手机及PC端，构建多屏互动式智能教育环境，优势融入，优化大屏教育。</p>
          <div class="appBg animated bounceIn">
            <img src="../../assets/show7.png" alt />
          </div>
        </div>
      </div>
      <div class="appIntrodoce">
        <div class="appIntrodoce-item">
          <img src="../../assets/icon_appClass.png" alt />
        </div>
        <div class="appIntrodoce_title">如何下载使用？</div>
        <div class="appIntrodoce_brief">
          <h3>电视端下载：</h3>
          <p>打开电视机上的【当贝应用市场】或【欢视商店】，点击首页的【搜索】按钮，搜索【幼儿启蒙课堂】【小学同步培优】【初中同步培优】【高中同步培优】【小新同步培优】等多款应用， 点击下载，注册账号后即可使用。</p>
        </div>
        <div class="appIntrodoce_brief">
          <h3>电脑端下载：</h3>
          <p>在电脑网页搜索【当贝应用市场】进入当贝应用市场后，搜索【幼儿启蒙课堂】【小学同步培优】【初中同步培优】【高中同步培优】【小新同步培优】等多款应用，点击下载安装包到U 盘；下载成功后把U盘插入电视机的USB插口，进行安装包安装，安装成功后即可使用。</p>
        </div>
      </div>

      <div class="appOffice">
        <div class="officeContain" data-aos="fade-up" data-aos-once="true">
          <h3>应用分类</h3>
          <p>拥有【幼儿启蒙课堂】【小学同步培优】【初中同步培优】【高中同步培优】【小新同步培优】等多款应用，具体包括幼教国学经典、同步精讲、中高考考点等三大核心内容，为广大儿童、中小学生提供寓教于乐的方式，符合0-18岁学生的数字电视学习平台，实现家校互动、在线选课等服务，为学生提供了便捷式的线上线下学习辅导方式，成为家长提供辅导孩子、全家互动学习的好帮手！</p>
          <div class="officeContain-img">
            <img src="../../assets/show8.png" alt />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name:'page3',
}
</script>
<style lang="scss">
.contain2 {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.bigTitle {
  width: 100%;
  height: 500px;
  background: #4967ff;
  &-contain {
    width: 1200px;
    margin: 0 auto;
    z-index: 2;
    position: relative;
    h3 {
      font-size: 56px;
      font-weight: bold;
      line-height: 95px;
      color: #ffffff;
      padding: 96px 0 16px 30px;
    }
    p {
      width: 564px;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      margin-bottom: 28px;
      color: #ffffff;
      opacity: 0.8;
      padding-left: 30px;
      box-sizing: border-box;
    }
    ul {
      margin-top: 39px;
      padding-left: 51px;
      li {
        font-size: 24px;
        font-weight: 500;
        line-height: 41px;
        color: #ffffff;
      }
    }
    .appBg {
      position: absolute;
      top: 110px;
      right: -139px;
      width: 900px;
      height: 643px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.appIntrodoce {
  width: 1200px;
  margin: 0 auto;
  height: 340px;
  &-left {
    display: inline-block;
    width: 142px;
    height: 142px;
    margin: 39px 0 0 4px;
    vertical-align: top;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-right {
    display: inline-block;
    width: 368px;
    margin: 66px 0 0 8px;
    vertical-align: top;
    h3 {
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #000000;
      line-height: 28px;
    }
    p {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      line-height: 27px;
      color: #5e6577;
      margin-top: 11px;
    }
  }
  &-item {
    display: inline-block;
    width: 571px;
    height: 106px;
    z-index: 3;
    margin: 34px 0 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.appOffice {
  width: 100%;
  background-color: #f4f7f9;
  padding-bottom: 98px;
  .officeContain {
    text-align: center;
    padding-top: 100px;
    h3 {
      display: inline-block;
      font-size: 38px;
      font-weight: bold;
      color: #000000;
      position: relative;
    }
    h3::before {
      position: absolute;
      top: 50%;
      left: -90px;
      display: block;
      content: "";
      background: url("../../assets/titleLeft.png") no-repeat;
      width: 74px;
      height: 3px;
      transform: translateY(50%);
    }
    h3::after {
      position: absolute;
      top: 50%;
      right: -90px;
      display: block;
      content: "";
      background: url("../../assets/titleRight.png") no-repeat;
      width: 74px;
      height: 3px;
      transform: translateY(50%);
    }
    p {
      // width: 732px;
      font-size: 32px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #5e6577;
      text-align: center;
      margin: 68px auto 0;
      b {
        font-size: 40px;
        color: #41cf14;
      }
    }
  }
  .officeContain-img {
    width: 1130px;
    height: 510px;
    margin: 100px auto 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.contain {
  animation-duration: 500ms;
}
.contain3 {
  .bigTitle {
    background: linear-gradient(180deg, #152d4f 0%, #152d4f 100%);
    .bigTitle-contain {
      h3 {
        font-size: 56px;
        padding: 114px 0 0 30px;
        line-height: 76px;
      }
      h3:nth-child(2) {
        padding: 0 0 0 30px;
      }
      .bananText {
        width: 448px;
        height: 132px;
        padding: 114px 0 0 30px;
      }
      p {
        margin-top: 22px;
        width: 457px;
      }
    }
    .appBg {
      width: 1081px;
      height: 611px;
      right: -180px;
      top: 126px;
    }
  }

  .appIntrodoce {
    height: 410px;
    .appIntrodoce-item {
      margin: -47px 0 0;
      width: 489px;
    }
    .appIntrodoce_title {
      font-size: 28px;
      font-weight: 500;
      line-height: 24px;
      color: #000000;
      margin: 73px 0 32px;
    }
    .appIntrodoce_brief {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #6e6e6e;
      h3 {
        color: #000000;
        padding: 5px 0;
      }
    }
  }
  .appOffice {
    padding-bottom: 115px;
    .officeContain-img {
      width: 1259px;
      height: 490px;
      margin-left: -59px;
    }
    .officeContain {
      width: 1200px;
      margin: 0 auto;
      p {
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        color: #000000;
        text-align: left;
      }
    }
  }
}
</style>